import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import App from '../../App';
import Hero from '../../components/Hero';
import ContactSection from '../../components/ContactSection';
import TechInfo from '../../components/TechInfo';
import SmallQuotationSection from '../../components/QuotationSection/SmallQuotationSection';
import MiniQuotationSection from '../../components/QuotationSection/MiniQuotationSection';
import Container from '../../components/Container';
import GreenSquareIcon from '../../assets/icons/GreenSquareIcon';
import CourtlyPageHeroImage from '../../gatsbyImages/CourtlyPageHeroImage';
import CourtlyMockupImage from '../../gatsbyImages/CourtlyMockupImage';
import CourtlyMockup2Image from '../../gatsbyImages/CourtlyMockup2Image';
import CourtlyMockup3Image from '../../gatsbyImages/CourtlyMockup3Image';
import CourtlyMockup4Image from '../../gatsbyImages/CourtlyMockup4Image';
import CourtlyMockup5Image from '../../gatsbyImages/CourtlyMockup5Image';
import SecondQuoteBackgroundImage from '../../gatsbyImages/SecondQuoteBackgroundImage';
import emilioBarajas from '../../assets/images/emilio-barajas-avatar.png';
import styles from './projectPages.module.scss';

const messages = defineMessages({
  title: {
    id: 'courtly.title',
    defaultMessage: 'COURTLY'
  },
  description: {
    id: 'courtly.courtlyDescription',
    defaultMessage:
      'Location: Washington, DC {br} Project Length: 2 years, ongoing {br}'
  },
  contactSectionTitle: {
    id: 'courtly.contactSectionTitle',
    defaultMessage: 'How can we help you?'
  },
  contactSectionDescription: {
    id: 'courtly.contactSectionDescription',
    defaultMessage: `Tell us the 1,000 foot view of your project and we'll put our brains, tools and {br} processes in place to make it a reality.`
  },
  techUsed: {
    id: 'courtly.techUsed',
    defaultMessage: 'Tech used: '
  },
  react: {
    id: 'courtly.react',
    defaultMessage: 'React'
  },
  java: {
    id: 'courtly.java',
    defaultMessage: 'Java'
  },
  reactNative: {
    id: 'courtly.reactNative',
    defaultMessage: 'React Native'
  },
  aws: {
    id: 'courtly.aws',
    defaultMessage: 'AWS'
  },
  firstSectionTitle: {
    id: 'courtly.firstSectionTitle',
    defaultMessage:
      'How StreamBright helped Courtly create a customized CRM from idea to first users'
  },
  firstSectionDescription1: {
    id: 'courtly.firstSectionDescription1',
    defaultMessage:
      'Courtly founder Emilio Barajas hit a roadblock. He had spent months researching and understanding the management of tennis clubs, to validate his idea for a tennis club CRM.'
  },
  firstSectionDescription2: {
    id: 'courtly.firstSectionDescription2',
    defaultMessage:
      'The research conclusions were clear: there is a dire need in the tennis market for a customized club management solution. Though there are generalized CRMs that can be used for tennis programs, most clubs still function with pen and printed papers in genotherms. Outdated, even for a sport that prides itself on tradition.'
  },
  firstSectionDescription3: {
    id: 'courtly.firstSectionDescription3',
    defaultMessage:
      'Enter Courtly, a tennis court CRM that would handle the business of tennis from A-Z. Feature ideas included program advertisement, a booking platform and class payment options. In order to get his idea off the ground, Barajas needed a tech team that had the chops to create a real product out of a concept brief.'
  },
  firstQuoteText: {
    id: 'courtly.firstQuoteText',
    defaultMessage:
      'The idea was sound, but we needed an experienced tech team to build out Courtly from concept to a web and mobile app — in a fast and user validated way. I wanted to bring in a partner that would take responsibility for the development process from UX to MVP, and bring a fresh perspective and ideas to the table.'
  },
  quotationDescription: {
    id: 'courtly.quotationDescription',
    defaultMessage:
      'At the same time, he wanted to make sure there was enough flexibility in the process to quickly validate the concept.'
  },
  miniQuotationDescription1: {
    id: 'courtly.miniQuotationDescription1',
    defaultMessage:
      'The most important goal during this phase was to plan and build an MVP with the highest priority features fast, to get feedback on the user experience and feature priorities ASAP.'
  },
  secondSectionTitle: {
    id: 'courtly.secondSectionTitle',
    defaultMessage: 'Courtly x StreamBright'
  },
  secondSectionDescription: {
    id: 'courtly.secondSectionDescription',
    defaultMessage:
      'After brainstorming the scope and roadmap for the Courtly MVP over a productive discovery process, StreamBright dove into development. Working together with an external design team, StreamBright not only provided the dev team but also took the lead and managed the entire project.'
  },
  miniQuotationDescription2: {
    id: 'courtly.miniQuotationDescription2',
    defaultMessage:
      'The first phase of our engagement resulted in crystal clear user flows and an intuitive interface. Within 6 months, StreamBright delivered a web and mobile platform with five major features, including segmented emailing, booking management, customer attendance, direct payment and lesson plans.'
  },
  thirdSectionTitle: {
    id: 'courtly.thirdSectionTitle',
    defaultMessage: 'The Results'
  },
  thirdSectionDescription1: {
    id: 'courtly.thirdSectionDescription1',
    defaultMessage:
      'Instead of waiting for the entire platform to be completely finished, the team used an agile development process to get the initial version in front of Courtly’s clients early on in the dev cycle.'
  },
  thirdSectionDescription2: {
    id: 'courtly.thirdSectionDescription2',
    defaultMessage:
      'Beta testers were able to validate the MVP, while the team simultaneously worked the feedback into the next sprints. Because of this approach, the next features that were built provided an uplift is user engagement across the board.'
  },
  thirdSectionDescription3: {
    id: 'courtly.thirdSectionDescription3',
    defaultMessage:
      'With Courtly’s full platform soon to be released into the wilds of tennis whites, Barajas looks back on his collaboration with StreamBright:'
  },
  miniQuotationDescription3: {
    id: 'courtly.miniQuotationDescription3',
    defaultMessage:
      'Adam and the StreamBright team developed an end-to-end mobile and web CRM solution, and then some.  What I loved was the attention to detail — building a roadmap where validation and development happened in tandem, managing the project from UX to final product flawlessly, and taking ownership over Courtly’s product as if it was their own.'
  }
});

function Courtly() {
  return (
    <App>
      <Helmet>
        <title>Streambright - Case Study: Courtly</title>
        <meta
          name="description"
          content="How StreamBright helped Courtly create a customized Tennis CRM from idea to first users."
        />
      </Helmet>
      <Hero
        icon={<GreenSquareIcon />}
        title={<FormattedMessage {...messages.title} />}
        description={
          <>
            <FormattedMessage
              {...messages.description}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
              <TechInfo title={messages.java} />
              <TechInfo title={messages.aws} />
              <TechInfo title={messages.reactNative} />
            </div>
          </>
        }
        backgroundImage={CourtlyPageHeroImage}
        image={CourtlyMockupImage}
        imageClassName={styles.courtlyHeroImage}
      />
      <Container>
        <div className={styles.title}>
          <FormattedMessage {...messages.firstSectionTitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription1} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription2} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription3} />
        </div>
        <div className={styles.imageContainer}>
          <CourtlyMockup2Image
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
          <CourtlyMockup3Image
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
          <CourtlyMockup4Image
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
          <div className={styles.leftBottomGreenIcon}>
            <GreenSquareIcon />
          </div>
        </div>
        <div className={styles.quotationContainer}>
          <SmallQuotationSection
            backgroundImage={SecondQuoteBackgroundImage}
            description={messages.firstQuoteText}
            avatar={<img src={emilioBarajas} alt="Emilio Barajas" />}
            authorName="Emilio Barajas"
            jobTitle="CEO"
            reverse
          />
          <div className={styles.blueIcon}>
            <GreenSquareIcon />
          </div>
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.quotationDescription} />
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription1}
        />
        <div className={styles.title}>
          <FormattedMessage {...messages.secondSectionTitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription} />
        </div>
        <div className={styles.image}>
          <CourtlyMockup5Image
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription2}
        />
        <div className={styles.title}>
          <FormattedMessage {...messages.thirdSectionTitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.thirdSectionDescription1} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.thirdSectionDescription2} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.thirdSectionDescription3} />
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription3}
        />
      </Container>
      <div id="Contact">
        <ContactSection
          title={messages.contactSectionTitle}
          description={
            <FormattedMessage
              {...messages.contactSectionDescription}
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </App>
  );
}

export default Courtly;
