import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const CourtlyMockup5Image = props => {
  const data = useStaticQuery(graphql`
    query {
      courtlyMockup5Image: file(relativePath: { eq: "courtly-mockup5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img fluid={data.courtlyMockup5Image.childImageSharp.fluid} {...props} />
  );
};

export default CourtlyMockup5Image;
