import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const CourtlyPageHeroImage = props => {
  const data = useStaticQuery(graphql`
    query {
      courtlyPageHeroImage: file(
        relativePath: { eq: "courtly-page-hero.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img fluid={data.courtlyPageHeroImage.childImageSharp.fluid} {...props} />
  );
};

export default CourtlyPageHeroImage;
